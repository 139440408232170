.about-section {
  color: #ebc1ff;

  &__first & {
    &__title {
      font-size: 7em;
      font-family: "Meadow";

      @media screen and (max-width: 800px) {
        font-size: 3.5em;
      }
    }

    &__maxi-arrow {
      position: absolute;
      left: 400px;
      top: 55%;
      transform: translate3d(0, -50%, 0);
      width: 30vw;
      max-width: 300px;
      height: auto;

      @media screen and (max-width: 1020px) {
        transform: translate3d(0, -50%, 0) rotate(90deg);
        left: 150px;
      }

      svg {
        display: block;
        width: 100%;
        height: 100%;
        fill: transparent;
        stroke: #ebc1ff;
        stroke-width: 5px;
      }

      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #111111;
        transform: scaleX(1);
        transform-origin: right center;
        transition: transform 0.6s cubic-bezier(0.77, 0, 0.18, 1);
        transition-delay: 2s;
      }

      &.in-view {
        &::after {
          transform: scaleX(0);
        }
      }
    }
  }

  &__second & {
    &__container {
      position: relative;
      padding-top: 10vh;
      width: 100%;
      height: 100%;
    }

    &__presentation {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      height: 100%;
      column-gap: 30px;
      row-gap: 40px;

      &__left {
        position: relative;
        width: 100%;
        height: 100%;

        &__title {
          mix-blend-mode: difference;
          font-family: "Meadow";
          font-size: 5em;
        }

        &__pic {
          position: absolute;
          top: 50px;
          left: 40px;
          width: 60vw;
          max-width: 300px;
          z-index: 0;
        }
      }

      &__right {
        position: relative;
        z-index: 2;
        mix-blend-mode: difference;
        width: 100%;
        max-width: 400px;

        &__title {
          font-size: 3em;
          margin-bottom: 0.8em;
        }

        &__paragraphs {
          p {
            word-break: normal;
            white-space: normal;
            font-size: 1.3em;
            margin: 0.5em 0;
          }
        }
      }
    }
  }

  &__third & {
    &__container {
      padding-top: 10vh;
      padding-right: 30vw;
    }

    &__title {
      mix-blend-mode: difference;
      font-family: "Meadow";
      font-size: 5em;

      @media screen and (max-width: 800px) {
        font-size: 3em;
      }
    }

    &__paragraph {
      font-size: 1.3em;
      max-width: 400px;
      word-break: normal;
      white-space: normal;

      a {
        color: white;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &__first &,
  &__second &,
  &__third & {
    @media screen and (max-width: 1020px) {
      &__container {
        padding-top: 0;
        padding-right: 0;
      }

      &__presentation {
        flex-direction: column;
        align-items: flex-start;

        &__left {
          &__title {
            font-size: 3em;
          }
        }

        &__right {
          margin-top: 280px;
        }
      }

      &__paragraph {
        max-width: 600px;
      }
    }
  }
}
