.u-anim {
  transition: all .6s cubic-bezier(0.4, 0, 0, 1);
  opacity: 0;
  transition-delay: 0s;
  transform: translateY(20px);
  transform-origin: center top;

  &.in-view {
    opacity: 1;
    transition-delay: .05s;
    transform: translateY(0);
  }
}

.in-view {
  @for $i from 0 to 100 {
    .-delay-#{ $i } {
      transition-delay: $i * 0.05s;
    }
  }
}
