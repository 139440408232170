.work-section {
  position: relative;
  z-index: 10;
  width: 100%;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 600px) {
    padding-bottom: 0;
  }

  &__works {
    position: relative;
    margin-top: 20vh;
    z-index: 1;

    &__heading {
      font-size: 1.8em;
      font-weight: 300;
      margin-bottom: 3em;
      margin-top: 1em;
      text-transform: uppercase;
      text-align: center;
    }
  }

  &__background {
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #303030;
    transform: scaleX(0.8);
    transition: transform 0.6s cubic-bezier(0.77, 0, 0.18, 1),
                background 0.8s cubic-bezier(0.4, 0, 0, 1);

    &.in-view {
      transform: scaleX(1);
      background: #125480;
    }
  }
}
