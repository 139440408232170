.c-hero {
  height: 90vh;
  width: 100%;

  &__wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__heading {
    &__title {
      position: relative;
      overflow: hidden;
      font-size: 10vw;
      font-weight: 700;
      -webkit-text-stroke: 1px white;
      -webkit-text-fill-color: transparent;

      span {
        display: inline-block;
        will-change: transform;
        transform: translate3d(0, 125%, 0) skew(80deg, 20deg);
        transition: .6s transform cubic-bezier(0.4, 0.1, 0.05, 1.4);

        .c-hero__wrapper.in-view & {
          transform: translate3d(0, 0, 0) skew(0, 0);
        }
      }
    }
  }

  &__subtitle {
    margin-top: 1em;
    padding: 0 30px;
    font-weight: 200;
    font-size: 1.1rem;
    text-align: center;
    opacity: 0;
    transition: .3s opacity cubic-bezier(0.4, 0, 0, 1);

    .c-hero__wrapper.in-view & {
      opacity: 1;
      transition-delay: 1.4s;
    }
  }
}