*,
*::before,
*::after {
  box-sizing: border-box;
}

body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

ul,
ol {
  padding: 0;
  margin: 0;
  list-style: none;
}

body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
}

a {
  text-decoration: none;
  color: inherit;
}

img,
picture {
  max-width: 100%;
  display: block;
}

button {
  background-color: none;
  outline: none;
  border: none;
  cursor: pointer;
}

input,
button,
textarea,
select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}