.o-wrapper {
  margin-left: auto;
  margin-right: auto;
  padding-left: 40px;
  padding-right: 40px;
  max-width: 1320px;
  width: 100%;

  @media screen and (min-width: 600px) {
    padding-left: 80px;
    padding-right: 80px;
  }

  &--big {
    max-width: 1600px;
  }
}