.c-navbar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  width: 100%;
  height: 70px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 calc(10% - 1em);
  margin-top: 2vh;
  font-size: 16px;
  font-weight: 400;

  @media screen and (max-width: 600px) {
    padding: 0 10px;
  }

  .js-loading-state.is-loading & {
    transition: .6s all cubic-bezier(0.4, 0, 0, 1) .1s;
    transform: translateY(-100%);
    opacity: 0;
  }

  &__wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      opacity: 0.8;
      transition: opacity 0.2s cubic-bezier(0.4, 0, 0, 1);
  
      &:hover {
        opacity: 1;
      }
    }

    .c-navbar__tagline {
      text-overflow: ellipsis;
      white-space: nowrap;
      flex-shrink: 1;
      overflow: hidden;
      position: relative;
      padding: .6em 1.2em;
      display: block;
  
      &::before {
        content: "";
        position: absolute;
        top: -1px;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(255,255,255,0.2);
        z-index: -1;
        border-radius: 80%;
        transform: translate3d(0, -100%, 0);
        transition: all 0.2s cubic-bezier(0.4, 0, 0, 1);
      }

      &:hover {
        &::before {
          transform: translate3d(0, 0, 0);
          border-radius: 0;
        }
      }
    }

    .c-navbar__nav {
      &__list {
        display: flex;
  
        &__item {
          a {
            position: relative;
            padding: .6em 1.2em;
            overflow: hidden;
            display: block;
  
            &::before {
              content: "";
              position: absolute;
              top: -1px;
              bottom: 0;
              left: 0;
              right: 0;
              background: rgba(255,255,255,0.2);
              z-index: -1;
              border-radius: 80%;
              transform: translate3d(0, -100%, 0);
              transition: all 0.2s cubic-bezier(0.4, 0, 0, 1);
            }
  
            &:hover {
              &::before {
                transform: translate3d(0, 0, 0);
                border-radius: 0;
              }
            }
          }
        }
      }
    }
  }
}