.c-hide-block {
  position: relative;
  margin-top: 2em;
  margin-bottom: 2em;

  @media screen and (max-width: 600px) {
    margin-top: 1.4rem;
    margin-bottom: 1.4rem;
  }

  &__paragraph {
    font-size: 2rem;

    @media screen and (max-width: 600px) {
      font-size: 1.4rem;
    }
  }

  &__hide-wrapper {
    position: relative;
    display: block;
    overflow: hidden;
    line-height: 1.25;

    &__inside {
      display: inline-block;
      transform: translate3d(0, 100%, 0);
      transition-property: transform;
      transition-duration: 0.8s;
      transition-timing-function: cubic-bezier(0.4, 0, 0, 1);

      .c-hide-block.in-view & {
        transform: translate3d(0, 0, 0);
      }
    }
  }
}
