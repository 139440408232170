::selection {
  background-color: #111111;
}

html {
  font-size: 16px;
  font-family: 'Inter', sans-serif;
}

body {
  background-color: #111111;
  color: #ffffff;
}
