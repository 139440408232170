.o-block {
  margin-top: 2rem;
  margin-bottom: 2rem;

  @media screen and (max-width: 600px) {
    margin-top: 1.4rem;
    margin-bottom: 1.4rem;
  }

  &--text {
    font-size: 1.6rem;

    @media screen and (max-width: 600px) {
      font-size: 4vw;
    }
  }

  &--image {
    width: 100%;
    max-width: 600px;
    max-height: 30vh;
    display: flex;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(18,84,128,0.2);
      pointer-events: none;
      transform: scale3d(1, 1, 1);
      transform-origin: top left;
      transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    .-small {
      max-height: 60px;
    }

    > img {
      width: 100%;
      flex: 1;
      object-fit: cover;
      transform: scale3d(1, 1, 1);
      transition: 0.3s transform cubic-bezier(0.4, 0, 0, 1);
    }

    &:hover {
      > img {
        transform: scale3d(1.1, 1.1, 1.1);
        transition: 0.6s transform cubic-bezier(0.4, 0, 0, 1);
      }

      &::after {
        transform: scale3d(1, 0, 1) skewY(-60deg);
        transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    }
  }
}
