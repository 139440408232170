.c-arche {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 60vh;
  border-radius: 360px 360px 10px 10px;
  margin: 0 auto;

  @media screen and (max-width: 600px) {
    height: 40vh;
  }

  &__image {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transform-origin: center center;
    transform: translate(-50%, -50%);
  }

  &::after {
    content: "";
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    background: url("~/public/img/noise.webp");
    animation: 90ms infinite noise;
    pointer-events: none;
  }
}

@keyframes noise {
  0% {
    background-position: 0 0;
  }
  10% {
    background-position: -5% -10%;
  }
  20% {
    background-position: -15% 5%;
  }
  30% {
    background-position: 7% -25%;
  }
  40% {
    background-position: 20% 25%;
  }
  50% {
    background-position: -25% 10%;
  }
  60% {
    background-position: 15% 5%;
  }
  70% {
    background-position: 0 15%;
  }
  80% {
    background-position: 25% 35%;
  }
  90% {
    background-position: -10% 10%;
  }
  100% {
    background-position: 0 0;
  }
}
