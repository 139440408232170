.c-hero-study {
  position: relative;
  width: 100%;
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  
    > h1 {
      font-size: 10vw;
      word-break: keep-all;
      font-weight: 600;
    }
  
    > h2 {
      position: relative;
      margin-right: 10px;
      font-weight: 400;
      font-size: 1.2em;
  
      &::before {
        content: "";
        position: absolute;
        left: -12px;
        top: 50%;
        transform: translateY(-50%);
        background: white;
        border-radius: 50%;
        width: 5px;
        height: 5px;
      }
    }
  }
}