.case-study-section {
  position: relative;
  width: 100%;
  padding-top: 30vh;
  padding-bottom: 3vh;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media screen and (max-width: 600px) {
    padding-top: 20vh;
  }

  &__view-case {
    position: absolute;
    z-index: 2;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &__link {
      position: relative;
      z-index: 2;
      background: white;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      transition: background 0.4s cubic-bezier(0.4, 0, 0, 1);

      img {
        opacity: 0;
        width: 20px;
        height: 20px;
        transform: translateY(-10px) rotate(45deg);
        transition: transform 0.4s cubic-bezier(0.215, 0.61, 0.355, 1),
          opacity 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
      }

      span {
        color: #111;
        font-weight: 400;
        transform: translateY(-10px);
        transition: transform 0.4s cubic-bezier(0.215, 0.61, 0.355, 1),
          color 0.2s cubic-bezier(0.4, 0, 0, 1);
      }

      &:hover {
        background: #111;

        img {
          opacity: 1;
          transform: translateY(0px) rotate(45deg);
        }

        span {
          color: #fff;
          transform: translateY(0);
        }

        + .case-study-section__view-case__ovale {
          div {
            width: 50%;
            height: 100%;
          }
        }
      }
    }

    &__ovale {
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 1;
      width: 250px;
      height: 250px;
      border-radius: 50%;
      transform: translate(-50%, -50%);

      &__symbol {
        position: absolute;
        z-index: 0;
        left: 50%;
        top: 50%;
        width: 100%;
        height: 50%;
        border-radius: 50%;
        border: 4px solid #fff;
        z-index: 2;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
        transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);

        &:nth-child(1) {
          animation: 5s linear 0s rotate1 infinite;
        }

        &:nth-child(2) {
          animation: 5s linear 0s rotate2 infinite;
        }
      }
    }

    @keyframes rotate1 {
      0% {
        transform: translate(-50%, -50%) rotate(0deg);
      }

      100% {
        transform: translate(-50%, -50%) rotate(360deg);
      }
    }

    @keyframes rotate2 {
      0% {
        transform: translate(-50%, -50%) rotate(-90deg);
      }

      100% {
        transform: translate(-50%, -50%) rotate(270deg);
      }
    }
  }

  &__article {
    position: relative;
    width: 100%;
    max-width: 1400px;
    padding: 0 30px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (min-width: 1200px) {
      padding: 0 160px;
    }

    &__header {
      width: 100%;
    }

    &__content {
      &__footer {
        margin-top: 5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .c-button {
          background: #0b5c51;
        }
      }
    }

    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__background {
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    &__arche {
      border-radius: 10000px 10000px 0 0;
      width: 100%;
      height: 100vh;
      background: #1d986f;
      background: linear-gradient(180deg, #1d986f 0%, #1b8764 100%);
    }

    &__solid {
      margin-top: -1px;
      width: 100%;
      height: calc(100% - 100vh);
      background: #1b8764;
      background: linear-gradient(180deg, #1b8764 0%, #15674d 100%);
    }
  }
}
