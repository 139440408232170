.c-return-button {
  position: relative;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.6s cubic-bezier(0.4, 0, 0, 1);

  &__arrow {
    width: 50%;
    transform: rotate(-90deg);
    display: block;
    position: relative;
    z-index: 2;
    transition: transform 0.3s cubic-bezier(0.77, 0, 0.18, 1);

    .c-return-button:hover & {
      transform: translate3d(-100%, 0, 0) rotate(-90deg);
    }
  }

  &__svg {
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(180deg);
    width: 100%;
    height: 100%;
    stroke: #fff;
    stroke-width: 4px;
    fill: transparent;
    stroke-dasharray: 400;
    stroke-dashoffset: 400;
    transform-origin: center center;
    transition: 1.4s stroke-dashoffset cubic-bezier(0.4, 0, 0, 1),0.3s transform cubic-bezier(0.77, 0, 0.18, 1);
  
    .c-return-button.in-view & {
      stroke-dashoffset: 0;
    }

    .c-return-button:hover & {
      transform: translate(-50%, -50%) scale(0.8) rotate(180deg);
    }
  }
}